import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios from 'axios'
import { get_dependant } from '@/config/endpoints'
import { QueryKey, UseQueryOptions, useQuery } from 'react-query'
import { dependantUid, IDependant } from '@umahealth/entities'
import { Timestamp } from 'firebase/firestore'

type QueryOptions = Omit<UseQueryOptions<IDependant<Timestamp>, unknown, IDependant<Timestamp>, QueryKey>, 'queryKey' | 'queryFn'>

function useGetDependant(dependantUid: dependantUid, queryOptions?: QueryOptions) {
	return useQuery<IDependant<Timestamp>>({
		queryKey: [ 'MegalithQuery', 'GetDependant' ],
		queryFn: async () => {
			let token = ''

			try {
				token = await getFirebaseIdToken()
			} catch (err) {
				throw new Error(`${err}`)
			}

			const headers = { 'Content-Type': 'Application/Json', 'Authorization': `Bearer ${token}`}

			const response = await axios.get<IDependant>(`${get_dependant}/${dependantUid}`, { headers })

			return response.data

		},
		enabled: !!dependantUid,
		...queryOptions,
	})
}

export default useGetDependant