import { IPatient } from '@umahealth/entities'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'
import axios from 'axios'
import { UMA_BACKEND_URL } from '@/config/endpoints'
import { useQuery } from 'react-query'

/** Trae los dependants validados de disintas OS de forma automática */
function useSyncDependantsFromCoverage({
	uid,
	coverageId
} : {
	uid?: string,
	coverageId?: string
} ) {
	return useQuery(
		['Megalith-Query', 'syncDependants', uid, coverageId],
		async () => {
			let token

			try {
				token = await getFirebaseIdToken()
			} catch (err) {
				throw new Error(`${err}`)
			}

			const headers = {
				'Content-Type': 'Application/Json',
				Authorization: `Bearer ${token}`,
				uid: uid /** Este siempre es el del usuario principal */,
				'x-api-key': process.env.NEXT_PUBLIC_UMA_BACKEND_APIKEY,
			}
			try {
			const response = await axios.post<IPatient[]>(
				`${UMA_BACKEND_URL}/dependant/external/sync`,
				{
					uid: uid,
					coverageId: coverageId,
				},
				{ headers }
			)

			return response.data 
			} catch (err) {
				return []
			}
		},
	{
		enabled: !!uid && !!coverageId
	})
}

export default useSyncDependantsFromCoverage
